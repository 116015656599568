@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

#particles canvas {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
}

.Particle {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
}